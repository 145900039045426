.App {
  text-align: center;
  background-color: rgb(0, 0, 0);
}

.App-header {
  background-color: rgb(0, 0, 0);
  min-height: 99vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 90vmin;
  pointer-events: none;
}

.App-body {
  background-color: rgb(0, 0, 0);
  min-height: 99vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-veranstaltungen {
  height: 90vmin;
  pointer-events: none;
}

.App-werbung {
  height: 90vmin;
  pointer-events: none;
}

.App-footer {
  font-size: calc(4px + 1vmin);
  background-color: rgb(0, 0, 0);
  color: white;
  padding-bottom: 10px;

}
